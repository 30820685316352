<!--  -->
<template>
  <div :class="['bar-ranking',languageType]">
    <div v-for="(item,index) in data" :key="index" class="ranking-item">
      <div class="ranking-info">
        <p :class="[(index+1)%2==0?'even':'odd']">{{ item.sort.toString().padStart(2, '0') }}</p>
        <p class="name" @mouseenter="hoverName" @mouseleave='initNameIndexShow=null' :data-index="'bar'+index" v-html="languageType=='ZN'?item.industryName:item.industryNameEn"></p>
        <!-- <span class="init-name" v-show="initNameIndexShow=='bar'+index">{{ languageType=='ZN'?item.industryName:item.industryNameEn }}</span> -->
        <img class="icon" :src="imgName[item.industryName]" alt="">
      </div>
      <img class="bar-style" :src="require(`../../assets/speaial_activity_img/bar_3d/bar_${item.sort}.png`)" alt="">
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: '',
  props: {
    data: {
      type: Array,
    },
    languageType: {
      type: String,
      default: 'ZN',
    },
  },
  components: {},
  data() {
    return {
      initNameIndexShow: null,
      imgName: {
        家用电器: require('../../assets/speaial_activity_img/image/icon/icon1.png'),
        手机数码: require('../../assets/speaial_activity_img/image/icon/icon2.png'),
        个护美妆: require('../../assets/speaial_activity_img/image/icon/icon3.png'),
        服装: require('../../assets/speaial_activity_img/image/icon/icon4.png'),
        '女鞋/男鞋/箱包': require('../../assets/speaial_activity_img/image/icon/icon5.png'),
        电脑办公: require('../../assets/speaial_activity_img/image/icon/icon6.png'),
        家具建材: require('../../assets/speaial_activity_img/image/icon/icon7.png'),
        食品饮料: require('../../assets/speaial_activity_img/image/icon/icon8.png'),
        母婴玩具: require('../../assets/speaial_activity_img/image/icon/icon9.png'),
        运动户外: require('../../assets/speaial_activity_img/image/icon/icon10.png'),
      },
    }
  },
  computed: {},
  watch: {},
  methods: {
    hoverName(e) {
      if (e.target.scrollWidth > e.target.offsetWidth) {
        this.initNameIndexShow = e.target.getAttribute('data-index')
      }
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
@import '@/style/rem.scss';
.bar-ranking {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}
.ranking-item,
.ranking-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ranking-info {
  width: rem(174);
  height: rem(140);
  justify-content: space-between;
  margin-bottom: rem(20);
  position: relative;
}
.ranking-item {
  // justify-content: center;
}
.bar-style {
  height: rem(440);
  width: rem(40);
}
.even {
  font-size: rem(30);
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffa826;
}
.odd {
  font-size: rem(30);
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fff594;
}
.name {
  width: 100%;
  white-space: nowrap;
  font-size: rem(22);
  font-family: Source Han Sans CN;
  font-weight: 450;
  color: #ffffff;
}
.icon {
  width: rem(40);
  height: rem(45);
}
.init-name {
  position: absolute;
  top: rem(20);
  left: rem(0);
  z-index: 10;
  font-size: rem(16);
  background: #ff9900;
  padding: rem(5) rem(12);
  border-radius: 20px 20px 20px 20px;
  width: max-content;
  color: #fff;
}
.EN {
  .name {
    font-size: rem(16);
    line-height: rem(16);
    font-weight: 500;
    word-wrap: break-word;
  }
}
@media screen and (max-width: 1280px), screen and (max-height: 970px) {
  .bar-style {
    height: rem(340);
    width: rem(38);
  }
}
@media screen and (max-height: 900px) {
  .bar-style {
    height: rem(320);
    width: rem(38);
  }
  .ranking-info {
    width: rem(154);
    height: rem(140);
    justify-content: space-between;
    margin-bottom: rem(20);
    position: relative;
  }
  .name {
    width: 100%;
    white-space: nowrap;
    font-size: rem(22);
    font-family: Source Han Sans CN;
    font-weight: 450;
    color: #ffffff;
  }
}
</style>