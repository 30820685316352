<template>
  <div class="view flex flex-d ai-c">
    <headers styleSize="min" :times="headTime"></headers>
    <img src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <TitleAndTime :time="dataTime">
      <img src="@/assets/speaial_activity_img/image/level_seven_title.png" class="title" alt="">
    </TitleAndTime>
    <div class="main flex-1">
      <BarChart3D :data="echratData"></BarChart3D>
    </div>
    <Annotation></Annotation>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import BarChart3D from '@/components/Big/BarChart3D'
import TitleAndTime from '@/components/Big/TitleAndTime.vue'
import Annotation from '@/components/Big/Annotation'
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  components: {
    BarChart3D,
    Headers,
    Annotation,
    TitleAndTime,
  },
  watch: {
    data(newValue, oldValue) {
      this.init(newValue)
    },
  },
  data() {
    return {
      echratData: [],
      headTime: '',
      dataTime: '',
    }
  },
  mounted() {
    this.init(this.data)
  },
  methods: {
    init(data) {
      this.sevenData = []
      if (data && data.length != 0) {
        this.dataTime = data[0].time + '数据时段'
        // data.forEach((item, index) => {
        //   if (index > 9) return
        //   let num = 11 - item.sort
        //   this.echratData.dataX.push(item.industryName)
        //   this.echratData.data.push(num)
        // })
        this.echratData = data
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/zn.scss';
</style>
